document.onscroll = (e) => {
  if (window.scrollY > 0) $(document.body).addClass('scroll');
  else $(document.body).removeClass('scroll');
};

const slideShow = () => {
  const current = $('#hero .slide.selected');

  const last = current.is(':last-child');
  const next = last
    ? $('#hero .slide:first-child')
    : $('#hero .slide.selected + .slide');

  if (last) {
    next.addClass('selected');
    setTimeout(() => current.removeClass('selected'), 800);
  } else {
    setTimeout(() => {
      next.addClass('selected');
      setTimeout(() => current.removeClass('selected'), 800);
    }, 800)
  }
};

setInterval(slideShow, 3000);
